import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './SignUpForm.css';

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const SignUpForm = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [consent, setConsent] = useState(false);

    useEffect(() => {
        // Check for URL parameter on page load
        const params = new URLSearchParams(window.location.search);
        if (params.get('verified') === 'true') {
            setStatus('Verification successful. Check your email for further details.');
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setStatus('Please enter a valid email address.');
            return;
        }

        if (!consent) {
            setStatus('Please provide consent to receive emails.');
            return;
        }

        setStatus('');  // Clear any previous status message
        setLoading(true);  // Set loading state to true

        try {
            const apiUrl = `${import.meta.env.VITE_API_URL}/api/signup`;
            console.log("Posting data to backend:", apiUrl);  // Log to verify correct backend endpoint

            // Make the request using axios
            const response = await axios.post(apiUrl, {email});

            // Check the response status and handle accordingly
            if (response.status === 200) {
                setStatus('Thank you for signing up! Check your email for further details.');
            } else {
                setStatus('Unexpected response from the server. Please try again later.');
            }
        } catch (error) {
            // Handle error responses
            console.error("Error occurred:", error);
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        setStatus('Invalid email address. Please enter a valid email.');
                        break;
                    case 500:
                        setStatus('Server error. Please try again later.');
                        break;
                    default:
                        setStatus('An unexpected error occurred. Please try again.');
                }
            } else if (error.request) {
                setStatus('Network error. Please check your connection and try again.');
            } else {
                setStatus('Something went wrong. Please try again later.');
            }
        } finally {
            setLoading(false);  // Stop the loading state
        }
    };

    return (<div className="signup-container">
        <div className="signup-card">
            <h1>Sign Up to Stay Updated!</h1>
            <p>
                Join our mailing list to receive updates on new patterns, products, and exclusive offers! As a perk, you'll get access to our online pattern library and be notified when new patterns are added.
            </p>
            <form className="signup-form" onSubmit={handleSubmit}>
                <input
                    id="email-input"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    aria-describedby="email-description"
                />
                <div id="email-description" className="visually-hidden">
                    Your email will be used to send you updates about new patterns, offers, and products.
                </div>
                <div className="consent-container">
                    <input
                        id="consent-checkbox"
                        type="checkbox"
                        checked={consent}
                        onChange={(e) => setConsent(e.target.checked)}
                        required
                    />
                    <label htmlFor="consent-checkbox" className="consent-label">
                        I consent to receive emails about updates, products, and offers. I understand I can unsubscribe at any time.
                    </label>
                </div>
                <button type="submit" disabled={loading} className="signup-button">
                    {loading ? 'Signing Up...' : 'Sign Up'}
                </button>
            </form>

            {status && <p className="status-message" aria-live="polite">{status}</p>}
        </div>
    </div>);
};

export default SignUpForm;